









































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Handout } from '@/interfaces/handouts/handouts';
import { RoundShort } from '@/interfaces/rounds/rounds';
import FirebaseGateway from '@/classes/firebaseGateway';
import { User } from '../../../interfaces/User';
import { Watch } from 'vue-property-decorator';
import HandoutDialogue from '@/components/group/handouts/handoutDialogue.vue';

@Component({ components: { HandoutDialogue } })
export default class Handouts extends Vue {
  get handouts(): Handout[] {
    const handouts = JSON.parse(JSON.stringify(this.$store.getters.handouts));
    handouts.sort((handoutA: Handout, handoutB: Handout) => {
      return handoutA.uploadedAt > handoutB.uploadedAt ? -1 : 1;
    });
    return handouts;
  }

  get members(): User[] {
    const members = JSON.parse(JSON.stringify(this.$store.getters.members));
    members.sort((memberA: User, memberB: User) => {
      return memberA.name.toLowerCase() < memberB.name.toLowerCase() ? -1 : 1;
    });
    return members;
  }

  get currentGroupId() {
    return this.$store.getters.currentGroup;
  }

  get currentUserUid() {
    const user = this.$store.getters.getUser;
    return user === null ? null : user.uid;
  }

  get lastPassedRoundTime() {
    const roundShorts: RoundShort[] = this.$store.getters.roundShorts;

    if (roundShorts.length === 0) {
      return 0;
    }

    const todayString = new Date().toISOString().substring(0, 10);

    // Filter out all rounds that are in the future
    const filteredRounds = roundShorts.filter((roundShort) => roundShort.date < todayString);

    if (filteredRounds.length === 0) {
      return 0;
    }

    // Next sort them by date and take the most recent one
    const lastRound = filteredRounds.sort((roundA, roundB) => (roundA.date < roundB.date ? -1 : 1)).pop();
    if (typeof lastRound === 'undefined') {
      return 0;
    }

    return Math.round(new Date(lastRound.date).getTime() / 1000);
  }

  private isLoading = true;
  private handoutLoading = false;
  private isDownloadingId: string | null = null;
  private uploadError: Error | string | null = null;
  private handoutToEdit: Handout | null = null;
  private handoutToDelete: Handout | null = null;
  private handoutDeleteDialogModel = false;
  private handoutDeletionInProgress = false;
  private showCreateHandoutDialog = false;
  private showEditHandoutDialog = false;
  private search = '';

  private headers = [
    { text: 'Datei', value: 'fileName' },
    { text: 'Größe', value: 'filesize' },
    { text: 'Hochgeladen am', value: 'uploadedAt' },
    { text: 'von', value: 'uploadedBy' },
    { text: 'freigegeben', value: 'visibleFor' },
    { text: 'Spielabende', value: 'roundShorts' },
    { text: 'Download', value: 'id' },
    { text: 'Aktionen', value: 'action', sortable: false },
  ];

  public mounted() {
    const handoutPromise = this.$store.dispatch('loadHandouts');
    const roundPromise = this.$store.dispatch('loadRoundShorts', this.$store.getters.currentGroup);
    Promise.all([handoutPromise, roundPromise])
      .then(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        this.uploadError = error;
        this.isLoading = false;
      });
  }

  /**
   * Acutally deletes the handout that is currently marked for deletion.
   */
  public deleteHandout() {
    if (this.handoutToDelete === null) {
      throw new Error('No handout marked for deletion');
    }
    this.isLoading = true;
    this.$store.dispatch('deleteHandout', this.handoutToDelete).finally(() => {
      this.isLoading = false;
      this.handoutToDelete = null;
    });
  }

  public editHandout(handout: Handout) {
    this.handoutLoading = true;
    this.$store
      .dispatch('uploadHandout', handout)
      .then(() => {
        this.onDialogueCancel();
      })
      .catch((error) => {
        this.uploadError = error;
      })
      .finally(() => {
        this.handoutLoading = false;
      });
  }

  private uploadHandoutFile(handout: Handout, file: File) {
    if (file === null) {
      throw new Error('File is null');
    }
    // Clear the error
    this.uploadError = null;
    const fileName = file.name;
    this.handoutLoading = true;

    new FirebaseGateway()
      .uploadFile(file, '/groups/' + this.currentGroupId + '/handouts/', true)
      .then((uploadTaskSnapshot) => {
        this.$store.dispatch('uploadHandout', handout).then(() => {
          this.showCreateHandoutDialog = false;
          this.handoutLoading = false;
        });
      })
      .catch((error) => {
        if (error instanceof Error && error.message === 'file_exists') {
          this.uploadError = 'Datei mit diesem Namen existiert bereits';
        } else {
          this.uploadError = error;
        }
      });
  }

  private beginHandoutEdit(handout: Handout) {
    this.handoutToEdit = handout;
    this.showEditHandoutDialog = true;
  }

  private onDialogueCancel() {
    this.showCreateHandoutDialog = false;
    this.showEditHandoutDialog = false;
    this.handoutToEdit = null;
  }

  private downloadHandout(handout: Handout) {
    this.isDownloadingId = handout.id;
    this.$store
      .dispatch('loadHandoutUrl', handout)
      .catch((error) => {
        this.uploadError = error;
      })
      .finally(() => {
        this.isDownloadingId = null;
      });
  }

  private getDateFromTimestamp(timestamp: number) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  }

  private getMemberById(userId: string) {
    const user = this.members.find((member) => member.uid === userId);
    if (user) {
      return user.name;
    } else {
      return '';
    }
  }

  private getRoundsTooltip(handout: Handout) {
    return handout.roundShorts.map((round) => round.title).join(', ');
  }

  private getVisibleForText(handout: Handout) {
    if (handout.visibility === 'group') {
      return 'Spielgruppe';
    } else if (handout.visibility === 'individual') {
      return handout.visibleFor.length + ' Spieler';
    } else {
      throw new Error('Unexpected handout visibility for ' + handout.id);
    }
  }

  private getVisibileForTooltip(handout: Handout) {
    if (handout.visibility === 'group') {
      return this.members.map((user) => user.name).join(', ');
    } else if (handout.visibility === 'individual') {
      return handout.visibleFor.map((userId) => this.getMemberById(userId)).join(', ');
    } else {
      throw new Error('Unexpected handout visibility for ' + handout.id);
    }
  }

  /**
   * Checks whether a handout was uploaded after most recent round
   */
  private isNewHandout(handout: Handout) {
    if (this.lastPassedRoundTime === 0) {
      return false;
    }

    return handout.uploadedAt > this.lastPassedRoundTime;
  }

  @Watch('handoutToDelete')
  private onHandoutToDeleteChange(newValue, oldValue) {
    this.handoutDeleteDialogModel = newValue !== null;
  }
}
