var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticClass:"text-center"},[_vm._v("Handouts")]),_c('v-row',[_c('v-flex',{attrs:{"sm12":""}},[(this.uploadError !== null)?_c('v-alert',[_vm._v(_vm._s(_vm.uploadError))]):_vm._e()],1)],1),_c('v-layout',{attrs:{"column":""}},[_c('v-row',{staticClass:"mb-1",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"sm12":"","md6":"","lg4":""}},[_c('v-btn',{on:{"click":function($event){_vm.showCreateHandoutDialog = !_vm.showCreateHandoutDialog}}},[_vm._v("Neues Handout")])],1),_c('v-col',{attrs:{"sm12":"","md6":"","lg4":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Handout suchen","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-flex',{attrs:{"sm12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.handouts,"loading":_vm.isLoading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.fileName",fn:function(ref){
var item = ref.item;
return [(_vm.isNewHandout(item))?_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'star-exclamation'],"color":'gold'}}):_vm._e(),_vm._v(" "+_vm._s(item.fileName)+" ")]}},{key:"item.filesize",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(Math.round(item.filesize / 1000) + ' kB'))]}},{key:"item.uploadedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getDateFromTimestamp(item.uploadedAt)))]}},{key:"item.uploadedBy",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getMemberById(item.uploadedBy)))]}},{key:"item.visibleFor",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.getVisibleForText(item)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getVisibileForTooltip(item)))])])]}},{key:"item.roundShorts",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.roundShorts.length))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getRoundsTooltip(item)))])])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(!item.downloadUrl)?_c('v-btn',{attrs:{"text":"","loading":_vm.isDownloadingId === item.id},on:{"click":function($event){return _vm.downloadHandout(item)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'external-link']}})],1):_c('v-btn',{attrs:{"text":"","small":"","href":item.downloadUrl,"target":"blank","color":"primary"}},[_vm._v("Download")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.uploadedBy === _vm.currentUserUid)?[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.beginHandoutEdit(item)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fal', 'edit']}})],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.handoutToDelete = item}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fas', 'trash']}})],1)]:_vm._e()]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_c('span',[_vm._v(_vm._s(pageStart)+" - "+_vm._s(pageStop)+" von "+_vm._s(itemsLength))])]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mb-3"},[_c('v-flex',{staticClass:"pl-3"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'star-exclamation'],"color":'gold'}}),_c('span',[_vm._v("= Seit dem letzten Spielabend hinzugefügt.")])],1)],1),_c('v-expand-transition',[(_vm.showCreateHandoutDialog || _vm.showEditHandoutDialog)?_c('v-row',[_c('v-flex',{staticClass:"pr-3",attrs:{"sm12":"","md6":""}},[_c('HandoutDialogue',{attrs:{"createMode":_vm.showCreateHandoutDialog,"members":_vm.members,"handoutToEdit":_vm.handoutToEdit,"loading":_vm.handoutLoading},on:{"handout:cancel":_vm.onDialogueCancel,"handout:create":_vm.uploadHandoutFile,"handout:update":_vm.editHandout}})],1)],1):_vm._e()],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"450"},model:{value:(_vm.handoutDeleteDialogModel),callback:function ($$v) {_vm.handoutDeleteDialogModel=$$v},expression:"handoutDeleteDialogModel"}},[(_vm.handoutDeletionInProgress)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-card',[_c('v-card-title',{staticClass:"text-h4"},[_vm._v("Handout löschen?")]),_c('v-card-text',[_vm._v(" Willst du den Handout "),_c('b',[_vm._v(_vm._s(_vm.handoutToDelete !== null ? _vm.handoutToDelete.fileName : ''))]),_vm._v(" wirklich löschen? Die Datei wird dadurch unwiederbringlich gelöscht. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.handoutDeletionInProgress},nativeOn:{"click":function($event){return _vm.deleteHandout()}}},[_vm._v("Ja")]),_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.handoutDeletionInProgress},nativeOn:{"click":function($event){_vm.handoutToDelete = null}}},[_vm._v("Nein")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }