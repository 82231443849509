






























import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import { Handout } from '@/interfaces/handouts/handouts';
import { RoundShort } from '@/interfaces/rounds/rounds';
import { User } from '@/interfaces/User';

@Component
export default class HandoutDialogue extends Vue {

  @Prop({type: Boolean})
  private createMode!: boolean;

  @Prop()
  private members!: User[];

  @Prop()
  private handoutToEdit!: Handout;

  @Prop({ default: false})
  private loading!: boolean;

  private selectedFile: File | null = null;
  private attachedRound: string[] = [];
  private visibleForWholeGroup = true;
  private visibleFor: string[] = [];
  private roundsLoading = true;

  public mounted() {
    this.visibleFor = [this.$store.getters.getUser.uid];
    this.$store.dispatch('loadRoundShorts', this.$store.getters.currentGroup).then(() => {
      this.roundsLoading = false;
      this.onHandoutToEditChange(this.handoutToEdit);
    });
  }

  public reset() {
    this.selectedFile = null;
    this.attachedRound = [];
    this.visibleForWholeGroup = true;
    this.visibleFor = [];
    this.$emit('handout:cancel');
  }

  get rounds(): RoundShort[] {
    return this.$store.getters.roundShorts;
  }

  get currentUserUid() {
    return this.$store.getters.getUser.uid;
  }

  get handoutForEmit(): Handout {
    const roundShorts: RoundShort[] = [];
    this.attachedRound.forEach((roundId) => {
      roundShorts.push(this.getRoundShortById(roundId));
    });
    if (this.createMode) {
      if (this.selectedFile === null) {
        throw new Error('Creating with no file selected');
      }
      return {
        id: null,
        fileName: this.selectedFile.name,
        filesize: this.selectedFile.size,
        uploadedBy: this.$store.getters.getUser.uid,
        uploadedAt: Math.round(new Date().getTime() / 1000),
        visibility: (this.visibleForWholeGroup) ? 'group' : 'individual',
        visibleFor: this.visibleFor,
        roundShorts,
      };
    } else {
      return {
        ...this.handoutToEdit,
        visibility: (this.visibleForWholeGroup) ? 'group' : 'individual',
        visibleFor: this.visibleFor,
        roundShorts,
      };
    }
  }

  private onFileChanged(file: File | null) {
    this.selectedFile = file;
  }

  private saveHandout() {
    if (this.createMode) {
      this.$emit('handout:create', this.handoutForEmit, this.selectedFile);
    } else {
      this.$emit('handout:update', this.handoutForEmit);
    }
  }

  private getRoundShortById(roundId: string) {
    const foundRound = this.rounds.find((round) => round.id === roundId);
    if (typeof foundRound === 'undefined') {
      throw new Error('Round with id ' + roundId + ' not found in rounds');
    }
    return foundRound;
  }

  @Watch('handoutToEdit')
  private onHandoutToEditChange(newHandout: Handout | null) {
    if (newHandout === null) {
      return;
    }
    this.visibleForWholeGroup = (newHandout.visibility === 'group');
    this.attachedRound = newHandout.roundShorts.map((roundShort) => (roundShort.id === null) ? '' : roundShort.id);
  }
}
